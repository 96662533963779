<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
                v-on:keydown.enter="get_records_by_phone"
              />
              <b-button variant="primary" @click="modal_record_details = true">
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(is_mail_send_to_agent)="data">
          <feather-icon
            :icon="data.item.is_mail_send_to_agent ? 'CheckIcon' : 'XIcon'"
            size="12"
            class="align-middle text-body"
          />
        </template>
        <template #cell(categories)="data">
          {{ data.item.categories.length }}
        </template>
        <template #cell(directions)="data">
          {{
            data.item.categories.reduce(
              (total, category) => total + category.questions.length,
              0
            )
          }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ records.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="get_records"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
      size="lg"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="display_name"
            rules="required"
          >
            <b-row class="mb-1">
              <b-col>
                <b-form-input
                  id="displayName"
                  v-model="currentData.display_name"
                  trim
                  :placeholder="lang('t_displayName')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12">
                <v-select
                  multiple
                  v-model="currentData.queues"
                  :options="queues"
                  :placeholder="lang('t_queue')"
                  :reduce="(val) => val.internal_name"
                  value="internal_name"
                  label="display_name"
                ></v-select>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <v-select
                  multiple
                  v-model="currentData.directions"
                  :options="['inbound', 'outbound', 'dialer']"
                  :placeholder="lang('t_direction')"
                ></v-select>
              </b-col>
              <b-col>
                <v-select
                  multiple
                  v-model="currentData.verdicts"
                  :options="finish_codes"
                  :placeholder="lang('t_finishCode')"
                  :reduce="(val) => val.finish_code"
                  value="finish_code"
                  label="finish_code"
                ></v-select>
              </b-col>
            </b-row>
            <b-row class="mb-50">
              <b-col cols="4">
                <b-form-input
                  v-model="currentData.min_point"
                  trim
                  :placeholder="lang('t_minPoint')"
                />
              </b-col>
              <b-col cols="4">
                <b-form-input
                  v-model="currentData.max_point"
                  trim
                  :placeholder="lang('t_maxPoint')"
                />
              </b-col>
              <b-col cols="4">
                <b-form-group class="mt-75">
                  <b-form-checkbox
                    @input="() => {}"
                    v-model="currentData.is_mail_send_to_agent"
                    class="custom-control-primary"
                  >
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                        {{ lang("t_isMailSendToAgent") }}
                      </span>
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :description="lang('t_enterEmailsSeparatedByCommas')"
                >
                  <b-form-input
                    v-model="currentData.to"
                    trim
                    :placeholder="lang('t_to')"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <v-select
                  v-model="currentData.mail"
                  :options="email_settings"
                  :placeholder="lang('t_account')"
                  :reduce="(val) => val"
                  label="display_name"
                ></v-select>
              </b-col>
            </b-row>

            <b-form-group :label="lang('t_categories')" label-for="criterions">
              <app-timeline>
                <app-timeline-item
                  v-for="(item, i) in currentData.categories"
                  :key="i"
                  variant="success"
                >
                  <div
                    class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                  >
                    <h6>
                      {{ `${lang("t_category")} ${i + 1}` }}
                      <b-button
                        @click="isDeleteCategory(item)"
                        class="ml-1 px-0 py-0"
                        size="sm"
                        variant="flat-danger"
                      >
                        <feather-icon icon="TrashIcon"></feather-icon>
                      </b-button>
                    </h6>
                    <small class="text-muted">{{
                      `${lang("t_questionCount")}: ${item.questions.length}`
                    }}</small>
                  </div>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group>
                        <b-form-input
                          v-model="item.title"
                          id="displayName"
                          trim
                          :placeholder="lang('t_column')"
                      /></b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group>
                        <b-form-input
                          v-model="item.description"
                          id="description"
                          trim
                          :placeholder="lang('t_description')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group>
                        <b-form-input
                          v-model="item.min"
                          id="displayName"
                          trim
                          :placeholder="lang('t_minPts')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group>
                        <b-form-input
                          v-model="item.max"
                          id="displayName"
                          trim
                          :placeholder="lang('t_maxPts')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group>
                        <b-button
                          @click="open_questions(item)"
                          block
                          variant="outline-primary"
                        >
                          <feather-icon
                            class="float-left"
                            icon="EditIcon"
                          ></feather-icon>
                          {{ lang("t_questionsAndAnswers") }}
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </app-timeline-item>
                <app-timeline-item variant="primary" :title="'1'">
                  <div
                    class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                  >
                    <h6>{{ lang("t_addtCategory") }}</h6>
                  </div>
                  <b-button @click="add_category" variant="outline-primary">
                    {{ lang("t_create") }}
                  </b-button>
                </app-timeline-item>
              </app-timeline>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      :ok-title="lang('t_ok')"
      v-model="modal_questions"
      title="Sorular & Cevaplar"
    >
      <!-- <b-card-text>
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1 w-50"
            :placeholder="lang('t_search')"
            v-on:keydown.enter="get_records_by_phone"
          />
          <b-button class="float-right" variant="success">
            Soru
            <feather-icon class="ml-50" icon="PlusIcon"></feather-icon>
          </b-button>
        </div>
      </b-card-text> -->
      <b-card-text>
        <b-table
          responsive="sm"
          :items="selected_category.questions"
          :fields="questionTableColumns"
          small
        >
          <template #cell(type)="data">
            <span v-if="data.item.is_multiple"> {{ lang("t_multiple") }}</span>
            <span
              v-else-if="!data.item.is_multiple && !data.item.is_point_range"
            >
              {{ lang("t_required") }}</span
            >
            <span v-else> {{ lang("t_pointRange") }}</span>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="updateQuestion(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="isDeleteQuestion(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card-text>
      <b-card-text>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <b-form-input
                v-model="question_template.question"
                id="question"
                trim
                :placeholder="lang('t_question')"
            /></b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <b-form-input
                v-model="question_template.description"
                id="description"
                trim
                :placeholder="lang('t_description')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2">
            <b-form-group class="mt-75">
              <b-form-checkbox
                @input="
                  () => {
                    question_template.answers = [];
                  }
                "
                v-model="question_template.is_point_range"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_pointRange") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <b-form-input
                :disabled="!question_template.is_point_range"
                v-model="question_template.min_point"
                id="minPoint"
                trim
                placeholder="Min Puan"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <b-form-input
                :disabled="!question_template.is_point_range"
                v-model="question_template.max_point"
                id="maxPoint"
                trim
                placeholder="Max Puan"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group class="mt-75">
              <b-form-checkbox
                @input="
                  () => {
                    question_template.is_multiple == true
                      ? (question_template.min_point = null)
                      : '';
                    question_template.is_multiple == true
                      ? (question_template.max_point = null)
                      : '';
                  }
                "
                v-model="question_template.is_multiple"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_multiple") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="2"> </b-col>

          <b-col v-if="!question_template.is_point_range" cols="2">
            <b-form-group>
              <b-button @click="add_answer" variant="outline-success">
                <span> Cevap </span>
                <feather-icon
                  icon="PlusIcon"
                  class="float-right ml-50"
                ></feather-icon>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <b-row>
          <b-col>
            <h5>Cevaplar</h5>
          </b-col>
        </b-row>
        <b-row v-for="(item, i) in question_template.answers" :key="i">
          <b-col cols="4">
            <b-form-group>
              <b-form-input
                v-model="item.answer"
                id="text"
                trim
                :placeholder="lang('t_answer2')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <b-form-input
                v-model="item.point"
                type="number"
                id="point"
                trim
                :placeholder="lang('t_point')"
            /></b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <v-select
                id="punish"
                v-model="item.punish"
                :options="punish_types"
                :placeholder="lang('t_punish')"
                :reduce="(val) => val.internal_name"
                value="internal_name"
                label="display_name"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <b-form-checkbox
                v-model="item.is_selected"
                class="custom-control-primary mt-75"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                    {{ lang("t_selected") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <b-form-group>
              <b-button
                @click="delete_answer(item)"
                class="float-right"
                variant="flat-danger"
              >
                <feather-icon size="16" icon="TrashIcon"></feather-icon>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              @click="save_question"
              class="float-right"
              variant="primary"
            >
              <span v-if="!isQuestionEdit">{{ lang("t_add") }}</span>
              <span v-else>{{ lang("t_edit") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea,
  BSpinner,
  VBToggle,
  VBTooltip,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    // UsersListFilters,
    AppTimelineItem,
    AppTimeline,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    BCardText,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      currentData: {
        display_name: "",
        directions: [],
        verdicts: [],
        queues: [],
        finish_codes: [],
        type: null,
        direction: null,
        queue: null,
        min_point: null,
        max_point: null,
        is_mail_send_to_agent: false,
        to: "",
        categories: [
          {
            title: "",
            description: "",
            min: null,
            max: null,
            questions: [],
          },
        ],
      },
      question_template: {
        question: "",
        description: "",
        min_point: null,
        max_point: null,
        is_point_range: false,
        is_required: false,
        is_multiple: true,
        answers: [],
        calculated_point: 0,
        choice: "",
        choices: [],
      },
      answers_template: {
        answer: "",
        point: "",
        selected: false,
        punish: null,
      },
      punish_types: [
        { internal_name: "question", display_name: "Soruyu" },
        { internal_name: "category", display_name: "Kategoriyi" },
        { internal_name: "form", display_name: "Formu" },
      ],
      searchQuery: "",
      modal_record_details: false,
      modal_questions: false,
      required,
      alphaNum,
      email,
      password,
      isQuestionEdit: false,
      queues: [],
      records: [],
      email_settings: [],
      selected_category: {},
      selected_question: {},
      selected_question_index: null,
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "categories",
          label: globalThis._lang("t_categories"),
          sortable: true,
        },
        {
          key: "directions",
          label: globalThis._lang("t_question"),
          sortable: true,
        },
        {
          key: "is_mail_send_to_agent",
          label: globalThis._lang("t_isMailSendToAgent"),
          sortable: true,
        },

        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
      questionTableColumns: [
        {
          key: "question",
          label: globalThis._lang("t_question"),
          sortable: true,
        },
        {
          key: "description",
          label: globalThis._lang("t_description"),
          sortable: true,
        },
        { key: "type", label: globalThis._lang("t_type"), sortable: true },
        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
    };
  },
  mounted: function () {
    this.get_records();
    this.get_queues();
    this.GetEMailSettings();
    this.finish_codes = globalThis.finish_codes;
  },
  methods: {
    GetEMailSettings: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/EMailSettings`)).data;
      this.email_settings = response;
    },
    get_queues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
        }
      }
    },
    reset_question() {
      this.question_template = {
        question: "",
        description: "",
        min_point: null,
        max_point: null,
        is_point_range: false,
        is_required: false,
        is_multiple: true,
        answers: [],
        calculated_point: 0,
        choice: "",
        choices: [],
      };
      this.isQuestionEdit = false;
    },
    save_question() {
      console.log("isQuestionEdit", this.isQuestionEdit);
      if (!this.isQuestionEdit) {
        this.selected_category.questions.push(
          JSON.parse(JSON.stringify(this.question_template))
        );
      } else {
        console.log("this.selected_question", this.selected_question);
        this.selected_question = this.question_template;
        this.selected_category.questions[this.selected_question_index] = this.selected_question;
        console.log("this.selected_question", this.selected_question);
        // this.selected_category.questions.find(e => )
        // Seçilen soru ile doldurulan bilgiler güncellenecek...!
      }
      this.reset_question();
      this.selected_question = {};
      this.selected_question_index = null;
    },
    add_answer() {
      this.question_template.answers.push(
        JSON.parse(JSON.stringify(this.answers_template))
      );
    },

    delete_answer(item) {
      this.question_template.answers = this.question_template.answers.filter(
        (e) => e != item
      );
    },
    open_questions(category) {
      this.selected_category = category;
      this.modal_questions = true;
    },
    add_category() {
      this.currentData.categories.push({
        title: "",
        description: "",
        min: null,
        max: null,
        questions: [],
      });
    },
    get_records: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(
          `/spv/v1/QualityForm/${this.currentPage}/${this.perPage}`
        )
      ).data;
      console.log("resp", response);
      this.records = response.result;
      this.totalUsers = response.count;
      this.is_progress = false;
    },
    get_records_by_phone: async function () {
      if (this.searchQuery == "") {
        this.get_records();
        return;
      }
      this.is_progress = true;

      var response = (
        await this.$http_in.get(`/spv/v1/QualityForm/${this.searchQuery}`)
      ).data;
      console.log("resp", response);
      this.records = response.result;
      this.totalUsers = response.count;
      this.is_progress = false;
    },
    check_data() {
      if (this.currentData.display_name == "") {
        return true;
      }
      return false;
    },

    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.is_progress = true;

      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`/spv/v1/QualityForm`, {
            data: this.currentData,
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/spv/v1/QualityForm`, {
            data: this.currentData,
          })
        ).data;
      }
      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;

      var response = (
        await this.$http_in.delete(
          `/spv/v1/QualityForm/${this.currentData._id}`
        )
      ).data;
      this.is_progress = false;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        display_name: "",
        directions: [],
        verdicts: [],
        queues: [],
        type: null,
        direction: null,
        queue: null,
        min_point: null,
        max_point: null,
        is_mail_send_to_agent: false,
        to: "",
        categories: [
          {
            title: "",
            description: "",
            min: null,
            max: null,
            questions: [],
          },
        ],
      };
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      //this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    updateQuestion(item) {

      this.reset_question();
      this.selected_question = item;
      this.selected_question_index = this.selected_category.questions.findIndex(e => e == item);
      this.question_template = {
        ...item,
        answers: JSON.parse(JSON.stringify([...item.answers])),
      };
      this.isQuestionEdit = true;
    },
    isDeleteCategory(item) {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCategory(item);
        }
      });
    },
    deleteCategory(item) {
      this.currentData.categories = this.currentData.categories.filter(
        (e) => e != item
      );
    },
    isDeleteQuestion(item) {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteQuestion(item);
        }
      });
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteQuestion: async function (item) {
      this.selected_category.questions =
        this.selected_category.questions.filter((e) => e != item);
    },
    deleteRecord: async function () { },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
