<template>
  <div>
    <div class="mb-1" style="overflow-x: auto; white-space: nowrap">
      <b-button
        style="width: 150px"
        class="ml-50"
        :variant="
          selectedButton != 'records' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'records'"
      >
        {{ lang("t_records") }}
      </b-button>
      <b-button
        style="width: 150px"
        class="ml-50"
        :variant="
          selectedButton != 'quality_form'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'quality_form'"
      >
        {{ lang("t_qualityForm") }}
      </b-button>
      <b-button
        class="ml-50"
        :variant="
          selectedButton != 'key_words' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'key_words'"
      >
        {{ lang("t_keywordCategories") }}
      </b-button>
    </div>
    <div>
      <records v-if="selectedButton == 'records'"></records>
      <quality-form v-if="selectedButton == 'quality_form'"></quality-form>
      <key-words v-if="selectedButton == 'key_words'"></key-words>
    </div>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'

import Records from './Records.vue'
import QualityForm from './list/List.vue'
import KeyWords from './KeyWordCategories/List.vue'


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'records',
    }
  },
  components: {
    Records,
    QualityForm,
    KeyWords,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },

}
</script>

<style>
</style>
